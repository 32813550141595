import { SUBMIT_CLIENT } from '../actions/types'; 

const initialState = {
    email: ""
  };

const clientReducer = (state = initialState, action) => {
    switch (action.type) { 
        case SUBMIT_CLIENT:
            return {
                  ...state,
                  email: action.payload.email,
              };
        default:            
            return state;
    }
};

export default clientReducer;