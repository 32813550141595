

// src/components/HeroSection.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import bg from '../Assets/landingPageAd/hero-section-bg-1.jpg';

function HeroSection() {
  
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column', // Ensure children are stacked vertically
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        marginBottom:'10px',
      }}
    >
      <Box
        sx={{
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          p: 4,
          borderRadius: 1,
          mb: { xs: 1, md: 2 }, // Margin bottom to create space between boxes
          zIndex: 2, // Ensure content is above the background
          mt: { xs: 70, md: 12 }, // Margin top to ensure proper spacing
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Make Every Journey Safe
        </Typography>
        <Button variant="outlined" color="inherit" size="large" href="#product">
          Belecoo carseat
        </Button>
      </Box>

      {/* Carousel for images */}
      <Box
        sx={{
          width: '70%', // Full width for responsiveness
          maxWidth: '200px', // Reduced max width for images container
          px: 2, // Padding for better spacing on small screens
          mt: { xs: 1, md: 2 }, // Margin top to ensure proper spacing
          mb: 2, // Margin bottom to ensure images are not too close to the bottom edge
        }}
      >
      </Box>
    </Box>
  );
}

export default HeroSection;
