import axios from "axios";
import { FETCH_USER } from "./types";
import { SUBMIT_COUPON } from "./types";
import { SUBMIT_CLIENT } from "./types";

// this fetch user in this case is our action creator
// the action creator in the default configuration will RETURN the action immedaitely ,
// that what we DONT want it to happen here, we want to return the action only after the
// api call is done successfully.

/*
- whenever the action creator gets called we instanly return a function,
- redux thunk will automaticall call the returned function and pass in the dispatch function as an argument.

- if redux thunk sees that we return a function instead of normal action , then redux thunk will automatically 
  call that function and pass in the DISPATCH function as an argument.
  
  

*/

/* 
TRADITIONAL CODE

const fetchUser = () => {
    axios.get('/api/current_user');
}
*/

/** REDUX THUNK CODE */
export const fetchUser = () => {
  return function (dispatch) {
    axios
        .get("/api/currtent_user")
        .then((res) =>
                    dispatch({
                        type: FETCH_USER,
                        payload: res.data,
                    })
    );
  };
};

// the below version of the fucntion is quite equavalent to the one above.
export const fetchUser1 = () => async dispatch => {
    const res = await axios.get("/api/currtent_user");
    dispatch({ type: FETCH_USER, payload: res.data});
}

export const submitCoupoon = values => async dispatch => {
  const res = await axios.post('/api/addCoupon', values);
  dispatch ({type: SUBMIT_COUPON, payload:  res.data });
}

// this action can recieve an object like {email:"email"} or {id":"", email:""...}
export const submitClient = client => async dispatch => {

  try {
    const res = await axios.post('/api/addClient', client);
    dispatch ({type: SUBMIT_CLIENT, payload: res.data.client})
    return res.data.client;
  } catch (error) {
    return error.response?.data || 'An error occurred while submitting the client data.';
  }
  
}