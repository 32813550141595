// src/components/Footer.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
  return (
    <Box sx={{ bgcolor: 'grey.900', color: '#fff', textAlign: 'center', py: 4 }}>
      <Typography variant="body2" component="p" gutterBottom>
        &copy; 2024 Our Product. All rights reserved.
      </Typography>
      <Box mt={2}>
        <IconButton color="inherit" href="#">
          <FacebookIcon />
        </IconButton>
        <IconButton color="inherit" href="#">
          <TwitterIcon />
        </IconButton>
        <IconButton color="inherit" href="#">
          <InstagramIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Footer;
