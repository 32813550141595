import React, { useState, useEffect , lazy, Suspense } from "react";
import { connect } from "react-redux"; // Import connect from react-redux


import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import ProductSection from "../components/ProductSection";


import Footer from "../components/Footer";
import Videos from "../components/Videos";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

const FeaturesSection = lazy(() => import("../components/FeaturesSection"));
const Faqs = lazy(() => import("../components/Faqs"));
const SupportSection = lazy(() => import("../components/SupportSection"));
const TestimonialsSection = lazy(() => import("../components/TestimonialsSection"));
const CallToActionSection = lazy(() => import("../components/CallToActionSection"));
const DiscountCoupon = lazy(() => import("../components/DiscountCoupon"));
const DiscountCouponSent = lazy(() => import("../components/DiscountCouponSent"));
const videos = [{ title: "Video", text: "", src: "4x0HOuvVXPA" }];


const LandingPage = ({ email }) => {
// States to control the discount dialog
const [open, setOpen] = useState(false);
const [minimized, setMinimized] = useState(false);
const [discountShown, setDiscountShown] = useState(false);
const [congratsShown, setCongratsShown] = useState(false);

// Effect to check the scroll event
useEffect(() => {
  const handleScroll = () => {
    const scrollThreshold = window.innerHeight * 1.8;
    if (window.scrollY > scrollThreshold && !discountShown) {
      // adjust the scroll position threshold here
      setOpen(true);
      setDiscountShown(true);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, [discountShown]);

// handlers
const handleClose = () => {
  setOpen(false);
  email !== "" ? setMinimized(false) : setMinimized(true);
};

const handleExpand = () => {
  setMinimized(false);
  setOpen(true && !(email !== ""));
};


  return (
    <>
      <Dialog open={open && !email} onClose={handleClose}>
        <DialogContent>
        <Suspense fallback={<div>Loading...</div>}>
          <DiscountCoupon />
        </Suspense>
        </DialogContent>
      </Dialog>
      <Dialog open={!congratsShown && email}>
        <DialogContent>
        <Suspense fallback={<div>Sending...</div>}>
          <DiscountCouponSent onCongratsShown={() => setCongratsShown(true)} />
          </Suspense>
        </DialogContent>
      </Dialog>
      {minimized && (
        <Button
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#72D281",
            color: "#fff",
            borderRadius: "0px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
            zIndex: 9999, // Set a high z-index value
          }}
          variant="contained"
          onClick={handleExpand}
        >
          Click to get your discount code
        </Button>
      )}
      <Navbar />
      <HeroSection />
      <Suspense fallback={<div>Loading...</div>}>
      <ProductSection />
      <FeaturesSection />
      <Suspense fallback={<div>Loading...</div>}><Videos videos={videos} /></Suspense>
      <Faqs />
      <SupportSection />
      <TestimonialsSection />
      <CallToActionSection />
      </Suspense>
      <Footer />
    </>
  );
};

// Map state to props
const mapStateToProps = (state) => ({
  email: state.client.email,
});

export default connect(mapStateToProps)(LandingPage);
