// src/components/Navbar.js
import React from 'react';
import { Box } from '@mui/material';
import logo from '../Assets/Noortana.png';

function Navbar() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: {
          xs: 0,
          md: 0,
        },
        left: {
          xs: '50%',
          md: 20,
        },
        transform: {
          xs: 'translateX(-50%)',
          md: 'none',
        },
        width: {
          xs: '60vw',
          md: '300px',
        },
        height: 'auto',
        zIndex: 2,
      }}
    >
      {/* Display logo using img tag */}
      <img
        src={logo}
        alt="Logo"
        style={{
          width: '100%',
          height: 'auto',
          display: 'block',
        }}
      />
    </Box>
  );
}

export default Navbar;
