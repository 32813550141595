import React from "react";
import { Box, Typography, Container } from "@mui/material";

const Videos = ({ videos }) => {
  return (
    <Container maxWidth={false} sx={{ px: 1, py: 1 }}>
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        See it in action
      </Typography>
      <Box id="product" sx={{ maxWidth: "1300px", mx: "auto" }}>
        <div>
          {videos.map((video, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
                mb: 4, // Adjust margin-bottom as needed
              }}
            >
              <iframe
                title={`YouTube video player ${index}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src={`https://www.youtube.com/embed/${video.src}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          ))}
        </div>
      </Box>
    </Container>
  );
};

export default Videos;
