import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";

import App from "./App";
import reducers from "./reducers";

import { ThemeProvider, createTheme } from "@mui/material/styles";
// adding the thuk thid way will allow us to use the dispatch function.
const store = createStore(reducers, {}, applyMiddleware(thunk));

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>
);
