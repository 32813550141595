// src/components/ProductSection.js
import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Container, // Import Container component
} from "@mui/material";
import Carousel from 'react-material-ui-carousel';

import product_thumb_1 from '../Assets/landingPageAd/product-1-thumb.jpg';
import product_thumb_2 from '../Assets/landingPageAd/product-2-thumb.jpg';
import product_thumb_3 from '../Assets/landingPageAd/product-3-thumb.jpg';
import product_thumb_4 from '../Assets/landingPageAd/product-4-thumb.jpg';
import product_thumb_6 from '../Assets/landingPageAd/product-6-thumb.jpg';
import use_case_2 from '../Assets/landingPageAd/use-case-2.jpg';



const specs = [
  "✅ EMBRACE EVERY JOURNEY WITH CONFIDENCE",
  "✅ EFFORTLESS 360° TURNS: Bond effortlessly, buckle with ease, and keep them rear-facing longer - all with a simple spin.",
  "✅ ONE-CLICK SWEDISH SECURITY: Buckle up worries in a single click, knowing they're safe with the strongest clasp in town.",
  "✅ BUILT TO PROTECT, BUILT TO LAST: HDPE strength shields them, lightweight design pampers you - peace of mind, mile after mile.",
  "✅ ADJUST WITH LOVE, IN ONE HAND: Growing smiles need growing comfort. Snuggle them in with effortless one-handed adjustments, every time.",
  "✅ FIVE LAYERS OF LOVE: SHIELD THEIR PRECIOUS HEAD, NECK, AND BODY WITH FIVE-POINT PROTECTION BEYOND COMPARE. Rest assured, their safety is our promise.",
  "✅ DOUBLE THE HEAD PROTECTION: Two layers of cushioning cradle their delicate head, absorbing bumps and whispering safe with every ride.",
  "✅ ISOFIX: CLICK. CLICK. DONE. International-standard security locks them in tight, leaving only room for happy adventures.",
  "✅ ECE Certified ECE R44/04: Exceeding even the strictest European standards, this seat is a fortress of love, built to bring you boundless peace of mind.",
  "✅ 100% SATISFACTION GUARANTEE: We're so confident you'll love this journey together that we offer a 100% satisfaction guarantee. Not happy? Return it for a full refund. Let us focus on the guarantee, so you can focus on the memories.",
];

const images = [product_thumb_1, product_thumb_2, product_thumb_3, product_thumb_4, product_thumb_6, use_case_2];

function ProductSection() {
  
  const handleClick = () => {
    const url = 'https://www.amazon.ae/Belecoo-adjustable-shock-reduced-0-12Years-Certificate/dp/B0CYXDG3GG/ref=sr_1_5?crid=2WKCUETJNN59Z&dib=eyJ2IjoiMSJ9.2_JF__iNDYawaX6gBkkSCBC7ExbRejJFoy46x2D11gci_Z08gdJ7Qmge2dVfPR4J7xzZtXqOUsD5qEGBZSmrdTYgYlgqpMCZCGypwdYP5X9_czQAqnuWzZLddvNBVosK91Ct7VKO1CE36pml1z90XHQyMlRQA9gj0hKE3T0v_J_9yMbjpOLEBBq8M83ewc-s4qDbFSYQWdqAvHA3_diz_Cc5lmbp-qjdJ1OMp3ixXvv2Dhcz3U9Upa7IRdyyciIWdnXaz9HWMJfFXVVwuMUjhxpiBpDF0qYttrtfiKV3Gzg.vNuNXNYcpstCsbUxTndVo92sDuuK4qsDpvqQ7Y9PtxE&dib_tag=se&keywords=belecoo+carseat+blue&qid=1721158134&sprefix=belecoo+carseat+blue+%2Caps%2C301&sr=8-5&ufe=app_do%3Aamzn1.fos.b4f74a7b-9d54-420b-9003-7e54957e6f89';
    window.location.href = url;
  };

  return (
    <Container maxWidth={false} sx={{ px: 2, py: 4 }}>
      <Box id="product" sx={{ maxWidth: '1300px', mx: 'auto' }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            {/* Carousel for images */}
            <Box
              sx={{
                width: '100%',
                maxWidth: { xs: '400px', md: '600px' }, // Larger max width for desktop
                px: 2,
                mt: { xs: 4, md: 6 },
                mb: 2,
              }}
            >
              <Carousel>
                {images.map((image, index) => (
                  <Box key={index} textAlign="center" mb={4}>
                    <img
                      src={image}
                      alt={`Product Thumbnail ${index + 1}`}
                      style={{
                        width: '100%', // Full width for responsiveness
                        height: 'auto',
                        maxHeight: { xs: '300px', md: '500px' }, // Larger height for desktop
                        borderRadius: '8px',
                        objectFit: 'cover', // Ensure images cover the container
                      }}
                    />
                  </Box>
                ))}
              </Carousel>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" component="h2" gutterBottom>
              Infant and baby carseat from Belecoo
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5" component="span" color="primary" fontWeight="bold">
                  632 AED
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.primary">
                  Discount coupon code available
                </Typography>
                <Button variant="outlined" color="primary" size="medium" onClick={handleClick}>
              Shop it from amazon.ae
            </Button>
              </Grid>
            </Grid>
            <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
              360 rotation, ISOFIX car seat with adjustable and shock-reduced headrest, 
              for Toddlers & Infants, 0-12 Years, 0-36KG, Safety Certificate ECE R44/04
            </Typography>
            <List>
              {specs.map((item, index) => (
                <ListItem key={index}>
                  <Typography variant="body2">
                    {item}
                  </Typography>
                </ListItem>
              ))}
            </List>

          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default ProductSection;