import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './authReducer';
import couponReducer from './couponReducer';
import clientReducer from './clientReducer';

export default combineReducers({
  auth: authReducer,
  form: reduxForm,
  coupon: couponReducer,
  client: clientReducer
});
