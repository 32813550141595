import React, { Component } from "react";

import "./App.css";
import LandingPage from './Pages/LandingPage';
import { connect } from "react-redux";
import * as actions from "./actions";
import { BrowserRouter, Route, Routes } from "react-router-dom";


class App extends Component {

  render() {
    return (
      <div className="container">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default connect(null, actions)(App);
